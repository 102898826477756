<template>
  <v-container>
    <v-form v-model="formValid" ref="form">
      <v-row justify="center" no-gutters>
        <v-col sm="12" md="8">
          <v-text-field
            v-model="userData.email"
            label="Seu e-mail"
            required
            :rules="[
              v => !!v || 'Insira um e-mail válido',
              v => /.+@.+/.test(v) || 'Insira um e-mail válido'
            ]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center" no-gutters>
        <v-col sm="12" md="8">
          <v-text-field
            v-model="userData.password"
            label="Crie uma senha"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="showPassword = !showPassword"
            :rules="[v => !!v || 'Insira uma senha válida']"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center" no-gutters>
        <v-col sm="12" md="8">
          <v-text-field
            label="Repita a senha"
            type="password"
            :rules="[v => v === userData.password || 'As senhas não conferem']"
            required
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      userData: {
        password: null,
        email: null
      },
      showPassword: false,
      formValid: false
    };
  },
  watch: {
    formValid(val) {
      if (val) {
        this.$emit("confirmar");
      } else {
        this.$emit("rejeitar");
      }
    }
  },
  mounted() {
    this.$root.$on("reset_fields", () => {
      this.$refs.form.reset();
    });
  }
};
</script>
